<template>
  <div>
    <div class="card p-2">
      <h3>{{ activity.activity }}</h3>

      <span class="text-primary">
        Frequency:<b class="ml-1">{{
          activity.frequency == "1"
            ? "Hourly"
            : activity.frequency == "2"
            ? "Daily"
            : activity.frequency == "3"
            ? "Weekly"
            : activity.frequency == "4"
            ? "Biweekly"
            : activity.frequency == "5"
            ? "Monthly"
            : activity.frequency == "6"
            ? "Quarterly"
            : activity.frequency == "7"
            ? "Half Yearly"
            : activity.frequency == "8"
            ? "Yearly"
            : ""
        }}</b></span
      >

      <p>{{ activity.description }}</p>
    </div>
    <div class="card p-2">
      <div class="">
        <h5>Primary Controls</h5>
        <!-- <v-select
          class="mr-bottom"
          v-model="controlname"
          placeholder="Search Standard"
          label="name"
          :options="controlslist"
          @search="getSearchControls"
          style="min-width:300px;"
          @input="getcontrollist"
        :reduce="option => option._id"
        />
        {{controlname}} -->
        <!-- <v-select
          v-model="controlname"
          label="name"
          :options="controlslist"
          @search="getSearchControls"
          autocomplete
          multiple
         
          @input="getcontrollist"
          placeholder="Search Standard"
        /> -->
        <!-- {{ allcontrols }} -->
        <treeselect v-model="selectedcontrols" :multiple="true" :options="controloptions" :disable-branch-nodes="true" />
<!-- {{ selectedcontrols }} -->
        <button class="btn btn-primary mt-1 mb-1 btn-sm" @click="addControlstoPeriodic">Map Controls to Periodic Activity</button>

       
      </div>

      <ul class="controls-list">
        <li v-for="control in controls" :key="control._id" class="mr-1">
          <feather-icon
            class="mr-right text-primary rotate"
            icon="CheckCircleIcon"
            size="15"
          />{{ control.name }}
        </li>
      </ul>
    </div>
    <div class="card p-2">
      <div>
        <h5>Legal Registers</h5>
        <v-select
          v-model="selectedlegal"
          label="title"
          :options="legaloptions"
          @search="getLegalRegisters"
          autocomplete
          multiple
          style="min-width:300px;"
          placeholder="Search Legal Registers"
          class="mb-1"
          :reduce="loc => loc.value"
        />
        <!-- {{ legaloptions }} -->
        <!-- {{ selectedlegal }} -->
        <button class="btn btn-primary btn-sm mb-1" @click="addLegaltoPeriodic">Map Legal Registers</button>
       
      </div>
    </div>
  </div>
</template>
<script>
import { BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  data() {
    return {
      activityid: "",
      activity: "",
      description: "",
      frequency: "",
      controls: [],
      controlname: "",
      controlslist: [],
      allcontrols:[],
      controloptions:[],
      legalregisters:[],
      legaloptions:[],
      selectedcontrols:[],
      selectedlegal:[],
    };
  },
  components: {
    BBadge,
    vSelect,
    Treeselect
  },
  mounted() {
    this.activityid = this.$route.params.id;
    this.GetActivity(this.activityid);
    this.getAllControls();
    this.getLegalRegisters();
  },
  methods: {
    addControlstoPeriodic(){
      const data = {
        control_ids:this.selectedcontrols
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts/${this.activityid }/controls`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          console.log(res.data.data.data);
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.activity[0],
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.log(err.data.data.activity[0]);
        });
    },
    addLegaltoPeriodic(){
      const data = {
        legal_register_ids:this.selectedlegal
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts/${this.activityid }/legal-registers`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "success",
              },
            });
          }
          
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.data.activity[0],
              icon: "EditIcon",
              variant: "danger",
            },
          });
          console.log(err.data.data.activity[0]);
        });
    },
    getAllControls(){
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/controls?dont_paginate=1`,
      };
      this.$http(options)
        .then((res) => {
          //   this.activity = res.data.data.activity;
          this.allcontrols = res.data.data.data;
          this.allcontrols.map(item =>{
            const a = {
              id:item._id,
              label:item.name
            }
            this.controloptions.push(a);
          })
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getcontrollist() {
      this.getcontrol(this.activityid);
    },
    getcontrol(activityid) {
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/periodic_acts/${activityid}?standard_id=${this.controlname[0]._id}`,
      };
      this.$http(options)
        .then((res) => {
          //   this.activity = res.data.data.activity;
          this.controls = res.data.data.controls;
          //   this.description = res.data.data.description;
          //   this.activity_id = res.data.data._id;
          //   this.frequency = res.data.data.frequency;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSearchControls() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/standards/search-list?name=${this.controlname}`,
      };
      this.$http(options)
        .then((res) => {
          this.controlslist = res.data.data;

          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    GetActivity(activityid) {
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/periodic_acts/${activityid}`,
      };
      this.$http(options)
        .then((res) => {
          this.activity = res.data.data.activity;
          this.controls = res.data.data.controls;
          this.description = res.data.data.description;
          this.activity_id = res.data.data._id;
          this.frequency = res.data.data.frequency;
          this.selectedcontrols =  res.data.data.activity.control_ids;
          this.selectedlegal = res.data.data.activity.legal_register_ids;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLegalRegisters() {
      const options = {
        method: "GET",

        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL +
          `/legal-registers`,
        // params: { page: num, limit: this.perPage },
      };
      this.$http(options)
        .then((res) => {
          this.legalregisters = res.data.data.data;
          this.legalregisters.map(item =>{
            const a = {
              title:item.title,
              value:item._id,
            }
            this.legaloptions.push(a);
          })
          // this.currentPage = res.data.data.current_page;
          // this.lastPage = res.data.data.last_page;
          // this.perPage = res.data.data.per_page;
          // this.rows = this.lastPage * 10;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.controls-list li {
  list-style: none;
}
</style>
